body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

svg.custom .node circle {
	fill: #F3F3FF;
	stroke: #2593B8;
	stroke-width: 1.5px;
}

svg.custom .node {
	cursor: pointer;
}

svg.custom .node text {
	font-size: 12px;
	fill: #242424;
}

svg.custom .node .cons {
	font-size: 12px;
	fill: #ff5c33;
}

svg.custom .node .pros {
	font-size: 12px;
	fill: #006600;
}

svg.custom .node .select {
	font-size: 20px;
	fill: #242424;
	font-weight : "bold";
}

svg.custom path.link {
	fill: none;
	stroke: #b3d9e6;
	stroke-width: 0.5px;
}

svg.custom path.cons {
	fill: none;
	stroke: #ff5c33;
	stroke-width: 0.5px;
}

svg.custom path.pros {
	fill: none;
	stroke: #006600;
	stroke-width: 0.5px;
}

svg.custom path.invalid {
	fill: none;
	stroke: #ff5c33;
	stroke-width: 0.5px;
	stroke-dasharray: 5,5;
}

.demo-editor {
	border: 1px solid #F1F1F1 !important;
	border-radius: 2px !important;
}